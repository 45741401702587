import Vue from 'vue'
import Vuex from 'vuex'

import httpClient from "../httpClient";

// import { Howl } from "howler";

//Firebase imports
import { auth } from '@/firebase/index'
import {
  // createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    userID: '',
    isSuperAdmin: false,
    isLoading: true,
    recordings: [],
    highQualityFile: '',
    regularQualityFile: '',
    playlistID: null,
    playlistURL: null,
    newPlaylistURL: null,
    isShowingPlaylistCreatedDialog: false,
    isUploading: false,
    loginError: null,
  },
  getters: {
    user: state => {
      return state.user;
    },
    userID: state => {
      return state.userID;
    },
    isSuperAdmin: state => {
      return state.isSuperAdmin;
    },
    isLoading: state => {
      return state.isLoading;
    },
    recordings: state => {
      return state.recordings;
    },
    highQualityFile: state => {
      return state.highQualityFile;
    },
    regularQualityFile: state => {
      return state.regularQualityFile;
    },
    playlistID: state => {
      return state.playlistID;
    },
    playlistURL: state => {
      return state.playlistURL;
    },
    newPlaylistURL: state => {
      return state.newPlaylistURL;
    },
    isUploading: state => {
      return state.isUploading;
    },
    isShowingPlaylistCreatedDialog: state => {
      return state.isShowingPlaylistCreatedDialog;
    },
    loginError: state => {
      return state.loginError;
    }
  },
  mutations: {
    //Mutation to update the user state
    //Takes in two arguments, the state and the payload. When we call this mutation, 
    // the payload will be user object from firebase auth
    //When the user logs out, we call the mutation and the payload will be null
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_USER_ID(state, userID) {
      state.userID = userID
    },
    SET_IS_SUPER_ADMIN(state, bool) {
      state.isSuperAdmin = bool
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_RECORDINGS(state, recordings) {
      state.recordings = recordings
    },
    SET_HIGH_QUALITY_FILE_TO_UPLOAD(state, highQualityFile) {
      state.highQualityFile = highQualityFile
    },
    SET_REGULAR_QUALITY_FILE_TO_UPLOAD(state, regularQualityFile) {
      state.regularQualityFile = regularQualityFile
    },
    SET_PLAYLIST_ID(state, playlistID) {
      state.playlistID = playlistID
    },
    SET_PLAYLIST_URL(state, playlistURL) {
      state.playlistURL = playlistURL
    },
    SET_NEW_PLAYLIST_URL(state, newPlaylistURL) {
      state.newPlaylistURL = newPlaylistURL
    },
    SET_IS_SHOWING_PLAYLIST_CREATED_DIALOG(state, isShowingPlaylistCreatedDialog) {
      state.isShowingPlaylistCreatedDialog = isShowingPlaylistCreatedDialog
    },
    SET_IS_UPLOADING(state, isUploading) {
      state.isUploading = isUploading
    },
    SET_NUMBER_OF_LISTENS(state, payload) {
      for (const recording in state.recordings) {
        if (recording.id == payload.id) {
          recording.numberOfListens = payload.updatedNumberOfListens
          return;
        }
      }
    },
    SET_LOGIN_ERROR(state, loginError) {
      state.loginError = loginError
    }
  },
  actions: {
    async login(context, { email, password }) {
      try {
        const response = await signInWithEmailAndPassword(auth, email, password)
        context.commit('SET_USER', response.user)
        context.commit("SET_USER_ID", response.user.uid);
        context.commit("SET_LOGIN_ERROR", null)
      } catch (error) {
        context.commit("SET_USER", null);
        context.commit("SET_USER_ID", null);
        context.commit("SET_LOGIN_ERROR", "Wrong email or password.")
      }
    },

    async logout(context) {
      await signOut(auth)
      context.commit('SET_USER', null)
      context.commit("SET_PLAYLIST_ID", null);
      context.commit("SET_PLAYLIST_URL", null);
      store.dispatch("loadDefaultPlaylist");
    },

    async loadDefaultPlaylist({ commit }) {
      store.dispatch("loadPlaylist", "default")
      commit("SET_PLAYLIST_ID", null);
      commit("SET_PLAYLIST_URL", null);
    },

    async loadPlaylist({ commit }, playlistID) {
      // validation
      if (!playlistID || playlistID.length != 7) {
        store.dispatch("loadDefaultPlaylist");
        return;
      }

      const playlistURL = process.env.VUE_APP_BASE_URL + "/" + playlistID;

      try {
        const response = await httpClient.get(playlistURL, { retry: 10, retryDelay: 3500 });
        // validation
        if (!response || !response.data || !response.data.recordings) {
          console.log('unable to retrieve playlist');
          return
        }
        commit('SET_RECORDINGS', response.data.recordings)
        commit('SET_PLAYLIST_URL', playlistURL) // TODO: do I need this?
      }
      catch (error) {
        console.log('unable to retrieve playlist:', error);
      }
      // setTimeout(() => {
      commit('SET_IS_LOADING', false)
      // }, 350);
    },

    async loadMasterPlaylist({ commit, state }) {
      // validation
      if (!state.userID) {
        console.log("error, check the input parameters");
        return;
      }

      try {
        const response = await httpClient.post(process.env.VUE_APP_BASE_URL, {
          "userID": state.userID
        }, { retry: 10, retryDelay: 3500 });
        // validation
        if (!response || !response.data || !response.data.recordings) {
          console.log('error, unable to retrieve playlist');
          return
        }
        commit('SET_RECORDINGS', response.data.recordings)
        commit("SET_PLAYLIST_ID", null);
        commit("SET_PLAYLIST_URL", null);

        if (response.data.isSuperAdmin) {
          commit('SET_IS_SUPER_ADMIN', true)
        }
      }
      catch (error) {
        console.log('error, unable to retrieve playlist:', error);
      }
      commit('SET_IS_LOADING', false)
    },


    async createPlaylist({ commit, state }, selectedTracksIDs) {
      if (!state.recordings || state.recordings.length == 0 || !selectedTracksIDs || selectedTracksIDs.length == 0 || !state.userID || state.userID == '') {
        console.log("problem creating playlist, check the input parameters");
        return;
      }

      const createPlaylistURL =
        process.env.VUE_APP_BASE_URL + "/playlist/create";
      try {
        let response = await httpClient.post(createPlaylistURL, {
          creatorID: state.userID, // TODO - change to actual ID when authentication is added
          recordings: selectedTracksIDs,
        }, { retry: 10, retryDelay: 3500 });
        commit('SET_NEW_PLAYLIST_URL', 'https://platinumcouchproductions.com/' + response.data.playlistID)
        commit('SET_IS_SHOWING_PLAYLIST_CREATED_DIALOG', true)
      } catch (err) {
        console.log("problem creating playlist:", err);
      }
    },

    resetPlaylistConstants({ commit }) {
      commit("SET_PLAYLIST_ID", null);
      commit("SET_PLAYLIST_URL", null);
      commit('SET_IS_SHOWING_PLAYLIST_CREATED_DIALOG', false)
    },

    setIsUploading({ commit }, isUploading) {
      commit('SET_IS_UPLOADING', isUploading)
    },

    setHighQualityFileToUpload({ commit }, file) {
      commit('SET_HIGH_QUALITY_FILE_TO_UPLOAD', file)
    },

    setRegularQualityFileToUpload({ commit }, file) {
      commit('SET_REGULAR_QUALITY_FILE_TO_UPLOAD', file)
    },

    async uploadFiles({ commit, state }, fileInfo) {
      if (!state.highQualityFile || !state.regularQualityFile || !fileInfo || !fileInfo.title) { //  || !fileInfo.duration
        return;
      }

      getAudioFileDuration(state.regularQualityFile, (duration) => {
        let formData = new FormData();
        formData.append('highQuality', state.highQualityFile);
        formData.append('regularQuality', state.regularQualityFile);
        formData.append('name', fileInfo.title);
        formData.append('date', fileInfo.date);
        formData.append('duration', duration);

        const uploadFileURL = process.env.VUE_APP_BASE_URL + "/upload";

        try {
          httpClient.post(uploadFileURL, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          }, { retry: 3, retryDelay: 3500 });
        } catch (err) {
          console.log("problem uploading files:", err);
        }

        commit('SET_IS_UPLOADING', false)
      })
    },

    async incrementNumberOfListens({ commit }, recordingID) {
      if (!recordingID) {
        return;
      }

      const incrementNumberOfListensURL =
        process.env.VUE_APP_BASE_URL + "/recording/incrementNumberOfListens";

      try {
        let response = await httpClient.post(incrementNumberOfListensURL, {
          id: recordingID
        }, { retry: 10, retryDelay: 3500 });
        const payload = {
          id: recordingID,
          updatedNumberOfListens: response.updatedNumberOfListens
        }
        commit('SET_NUMBER_OF_LISTENS', payload)
      } catch (err) {
        console.log("problem incrementing number of listens:", err);
      }
    }
  },
})

auth.onAuthStateChanged(function (user) {
  store.commit('SET_IS_SUPER_ADMIN', false)
  if (user) {
    store.commit("SET_USER", user);
    store.commit("SET_USER_ID", user.uid);

    if (!store.getters.playlistURL) {
      store.dispatch("loadMasterPlaylist");
    } else {
      store.dispatch("loadPlaylist", store.getters.playlistID)
    }
  } else {
    store.commit("SET_USER", null);
    store.commit("SET_USER_ID", null);

    if (!store.getters.playlistURL) {
      store.dispatch("loadDefaultPlaylist");
    } else {
      store.dispatch("loadPlaylist", store.getters.playlistID)
    }
  }
})

function getAudioFileDuration(file, callback) {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onloadend = (e) => {
    const ctx = new AudioContext();
    const audioArrayBuffer = e.target.result;
    ctx.decodeAudioData(audioArrayBuffer, data => {
      callback(formatTime(data.duration))
    }, error => {
      console.error("uploadFiles error:", error);
    });
  };
}

function formatTime(numberOfSeconds) {
  var date = new Date(0);
  date.setSeconds(numberOfSeconds);
  var timeString = date.toISOString().substring(14, 19);
  return timeString;
}

export default store