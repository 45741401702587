// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAQ8FPb55tibpMa8FRpiSC1cC0Ab21QNhc",
  authDomain: "platinum-couch-productions.firebaseapp.com",
  projectId: "platinum-couch-productions",
  storageBucket: "platinum-couch-productions.appspot.com",
  messagingSenderId: "194927982129",
  appId: "1:194927982129:web:1dfbcad75e37efdae74710",
  measurementId: "G-KRTKEB28PZ"
};

//initialize the firebase app
initializeApp(firebaseConfig)

//initialize firebase auth
const auth = getAuth()

//export the auth object
export { auth }