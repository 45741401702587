import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#B0B795',
        secondary: '#9A9D99',
        tertiary: '#e9e9e9',
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: '#efefef',
        secondary: '#aaa',
        tertiary: '#535353',
      },
    },
    options: {
      customProperties: true,
    }
  },
});
