import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // TODO: decide if these are needed
    // "Access-Control-Allow-Origin": process.env.VUE_APP_BASE_URL,
    // "Access-Control-Allow-Methods": "POST", "GET", "DELETE"
  }
});


httpClient.interceptors.response.use(undefined, (err) => {
  const { config } = err;

  if (config.retry <= 1) {
    return
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, config.retryDelay || 3000);
  });
  return delayRetryRequest.then(() => httpClient(config));
});

export default httpClient;